export function exportToCsv(filename, rows) {
    var processRow = function (row) {
        var finalVal = '';
        for (var j = 0; j < row.length; j++) {
            var innerValue = row[j] === null ? '' : row[j].toString();
            if (row[j] instanceof Date) {
                innerValue = row[j].toLocaleString();
            };
            var result = innerValue.replace(/"/g, '""');
            if (result.search(/("|,|\n)/g) >= 0)
                result = '"' + result + '"';
            if (j > 0)
                finalVal += ',';
            finalVal += result;
        }
        return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
        csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
        navigator.msSaveBlob(blob, filename);
    } else {
        var link = document.createElement("a");
        if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function translatePrizeList(prizeCode) {
	if (prizeCode === "B") {
		return "HK$1,000 CP"
	}

	if (prizeCode === "C") {
		return "HK$600 CP"
	}

	if (prizeCode === "D") {
		return "HK$300 CP"
	}

	if (prizeCode === "E") {
		return "Zoono CP"
	}

	return ""
}

export function translateBonusList(bonusCode) {
	if (bonusCode === "2") {
		return "Postcard Set + Photoframe"
	}

    if (bonusCode === "1") {
		return "Postcard"
	}

	return ""
}

export function translatePrize(prizeCode) {
	if (prizeCode === "B") {
		return "Zung Fu Service, Repair & Accessories Cash Voucher HK$1,000"
	}

	if (prizeCode === "C") {
		return "Zung Fu Service, Repair & Accessories Cash Voucher HK$600"
	}

	if (prizeCode === "D") {
		return "Zung Fu Service, Repair & Accessories Cash Voucher HK$300"
	}

	if (prizeCode === "E") {
		return "Zoono Cabin Sanitizing Service (one-time)"
	}

	return ""
}

export function translateBonus(bonusCode) {
	if (bonusCode === "2") {
		return "Limited Edition Postcard Full Set + Photoframe"
	}

    if (bonusCode === "1") {
		return "Limited Edition Postcard"
	}

	return ""
}