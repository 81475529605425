import React, { useState } from 'react'
import { Navigate, useLocation, Link } from 'react-router-dom'
import { useSubscribe } from '../api'
import moment from 'moment'
import { makeStyles, useTheme } from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Toolbar from '@mui/material/Toolbar'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import LinearProgress from '@mui/material/LinearProgress'
import Hidden from '@mui/material/Hidden'
import Drawer from '@mui/material/Drawer'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import Router from './Router'
import Menu from './Menu'

const drawerWidth = 240;

const toolbarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: (props) => props.lights ? '#f1f1f1' : '#383838',
    height: 65,
    minHeight: 65,
    paddingTop: 0,
    position: 'sticky',
    top: 0,
    zIndex: 2
  },
  title: {
    fontStyle: 'italic',
    fontWeight: '100',
    fontVariant: 'small-caps',
    flex: 1,
    padding: '0.37em 0',
    color: theme.palette.text.primary,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth
  },
  menuContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  menuButton: {
    marginRight: 10,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    background: 'transparent !important',
    color: (props) => props.lights ? 'black' : 'white'
  },
  date: {
    fontSize: '0.9em',
    maxWidth: '13em',
    textAlign: 'end',
    color: (props) => props.lights ? 'black' : 'white',
  },
  breadcrumbBar: {
    background: (props) => props.lights ? 'white' : 'rgb(90, 90, 90)',
  },
  breadcrumbs: {
    background: (props) => props.lights ? '#e6e6e6' : '#4c4c4c',
    padding: '5px 10px',
    borderRadius: 5
  },
  breadcrumb: {
    color: theme.palette.primary.main,
    fontSize: '0.9em'
  },
  breadcrumbOff: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    pointerEvents: 'none',
    fontSize: '0.9em',
  }
}))

const appStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      marginLeft: drawerWidth,
    },
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1'
  }
}))

const DateDisplay = ({ time }) => (moment.unix(time / 1000000000).format('dddd, MMMM Do Y LTS'))

const Dashboard = ({ status, authorize, dispatch }) => {
  const location = useLocation()
  const [time, socket] = useSubscribe(null, authorize)
  const active = socket && socket.readyState === WebSocket.OPEN
  const [mobileOpen, setMobileOpen] = useState(false)
  const lights = window.localStorage.getItem('lights') === 'on'
  const toolbar = toolbarStyles({
    mobile: useMediaQuery(useTheme().breakpoints.down('xs')),
    location,
    lights
  })
  const app = appStyles()
  const pathname = location.pathname.split('/')
  const isDashboard = pathname.length === 2
  const isGame = pathname.length === 4 && pathname[2] === "game"
  const isSettings = pathname.length === 3 && pathname[2] === "settings"


  if (status === 'unauthorized') {
    return (<Navigate to="/login" />)
  }

  return (!time) ? (<LinearProgress />) : (
    <div className={app.root}>
      <Toolbar className={toolbar.root}>
        <div className={toolbar.menuContainer}>
          <IconButton disableRipple
            color="inherit"
            aria-label="Open drawer"
            edge="start"
            onClick={() => setMobileOpen(!mobileOpen)}
            className={toolbar.menuButton}
          >
            <Icon>menu</Icon>
          </IconButton>
        </div>
        <Hidden smUp>
          <Typography className={toolbar.title} variant="h5" component="h3">
            circa
          </Typography>
        </Hidden>
        <div className={toolbar.date}>
          {(() => active ? <DateDisplay time={time} /> : <CircularProgress size={24} />)()}
        </div>
      </Toolbar>
      <Toolbar className={toolbar.breadcrumbBar}>
        <Breadcrumbs className={toolbar.breadcrumbs} aria-label="Breadcrumb">
          {isDashboard && (
            <span className={toolbar.breadcrumbOff}>Dashboard</span>
          )}
          {isSettings && (
            <span className={toolbar.breadcrumbOff}>Settings</span>
          )}
          {isGame && (
            <Link className={toolbar.breadcrumb} to="/dashboard">Dashboard</Link>
          )}
          {isGame && (
            <span className={toolbar.breadcrumbOff}>Game</span>
          )}
        </Breadcrumbs>
      </Toolbar>
      <nav className={toolbar.drawer} aria-label="Main menu">
        <Hidden smUp implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor={'left'}
            open={mobileOpen}
            onOpen={() => setMobileOpen(true)}
            onClose={() => setMobileOpen(false)}
            classes={{
              paper: toolbar.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            <Menu location={location} setMobileOpen={setMobileOpen} />
          </SwipeableDrawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: toolbar.drawerPaper,
            }}
            variant="permanent"
            open
          >
            <Menu location={location} setMobileOpen={setMobileOpen} />
          </Drawer>
        </Hidden>
      </nav>
      <Router dispatch={dispatch} authorize={authorize} />
    </div>
  )
}

export default Dashboard

