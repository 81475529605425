import React, {useState } from 'react'
import moment from 'moment'
import { fetch, put, useSubscribe } from '../api'
import { makeStyles } from '@mui/styles'
import LinearProgress from '@mui/material/LinearProgress'
import CircularProgress from '@mui/material/CircularProgress'

import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import SnackbarContent from '@mui/material/SnackbarContent'
import Icon from '@mui/material/Icon'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import { validate, update } from '../forms'
import { useMatch } from 'react-router-dom'
import { translatePrize, translateBonus } from './Constants'

const rootStyles = makeStyles((theme) => ({
    root: {
      borderRadius: 0,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 1',
      background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6',
      paddingBottom: 23,
      position: (props) => 'inherit',
      width: '100%',
    },
    warningContainer: {
      margin: '0 auto',
      maxWidth: 728
    },
    fail: {
      backgroundColor: 'brown',
      maxWidth: 'unset',
      marginBottom: 10,
      margin: '1em'
    },
    warning: {
      backgroundColor: '#f1932c',
      maxWidth: 'unset',
      marginBottom: 10,
      margin: '1em'
    },
    warningText: {
      color: 'white',
      fontSize: '0.96em',
      lineHeight: 2,
      fontWeight: 100
    },
    warningIcon: {
      verticalAlign: 'bottom',
      color: '#f0cf81'
    },
    form: {
      padding: '1em',
      maxWidth: 700,
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        margin: '0 auto',
      }
    },
    formSubmit: {
      marginTop: 20,
      marginLeft: 10
    },
    formDelete: {
      marginTop: 20,
      background: theme.palette.error.main
    },
    formButtonWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'flex-end'
    },
    formProgress: {
      position: 'absolute',
      top: '67%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    text: {
      overflowWrap: 'break-word',
      padding: '1em'
    }
  }))

const Game = ({ authorize }) => {
  const match = useMatch('/dashboard/game/:id')
  const lights = window.localStorage.getItem('lights') === 'on'
  const styles = rootStyles({ lights })
  const [game, socket] = useSubscribe('games/' + match.params.id, authorize)
  const active = socket && socket.readyState === WebSocket.OPEN
  //   const theme = useTheme()
  const fontColor = {
    style: { color: lights ?  'rgb(50, 50, 50)' : 'rgb(250, 250, 250)' }
  }

  // fields
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [mobile, setMobile] = useState('')
  const [prizeCode, setPrizeCode] = useState('')
  const [prize, setPrize] = useState('')
  const [bonus, setBonus] = useState('')
  const [staffName, setStaffName] = useState('')

  // field flags
  const [editing, setEditing] = useState([])
  const [submitted, setSubmitted] = useState(false)

  // form flags
  const [fail, setFail] = useState('')
  const [loading, setLoading] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [updated, setUpdated] = useState(-1)

  const fields = {
    name: {
      error: () => name.trim().length < 1,
      value: name,
      set: setName,
      message: 'name must have have at least 1 character'
    },
    email: {
        error: () => email.trim().length < 1,
        value: email,
        set: setEmail,
        message: 'email must have have at least 1 character'
    },
    mobile: {
        error: () => mobile.trim().length < 1,
        value: mobile,
        set: setMobile,
        message: 'mobile must have have at least 1 character'
    },
    prizeCode: {
        error: () => prizeCode.trim().length < 1,
        value: prizeCode,
        set: setPrizeCode,
        message: 'prize code must have have at least 1 character'
    },
    prize: {
        error: () => false,
        value: prize,
        set: setPrize,
        message: ''
    },
    bonus: {
        error: () => false,
        value: bonus,
        set: setBonus,
        message: ''
    },
    staffName: {
        error: () => staffName.trim().length < 2,
        value: staffName,
        set: setStaffName,
        message: 'staff name name must have have at least 2 characters'
      },
  }
  const validation = validate(fields)

  if (active && game && game.updated !== updated) {
    update(fields, editing, {
        data: {
            name: game.data.FirstName + " " + game.data.LastName,
            email: game.data.Mail,
            mobile: game.data.Mobile,
            prizeCode: game.data.PrizeCode,
            prize: translatePrize(game.data.Prize),
            bonus: translateBonus(game.data.Bonus),
            isEU: game.data.isEU ? "yes" : "no",
            played: moment.unix(game.created / 1000000000).format('DD/MM/YY'),
            redeemed: game.data.Redeemed ? moment.unix(game.data.Redeemed / 1000000000).format('DD/MM/YY') : '-',
            staffName: ""
        }
    })
    setUpdated(game.updated)
    setLoading(false)
  }
  const onFocus = (key) => setEditing([...editing, key])
  const onBlur = (e, key) => (e.target.value === game.data[key]) ? setEditing(editing.filter((field) => field !== key)) : null

  const redeem = async () => {
    setSubmitted(true)
    setConfirm(false)
    if (!validation.error) {
        try {
            setLoading(true)
            await put('redeem/' + game.index, authorize, {
                StaffName: staffName
            })
        } catch (e) {
            setFail(`Something went wrong ('unable to process request, please contact support')`)
            setLoading(false)
        }
    }
  }

  const resendEmail = async () => {
    try {
      setLoading(true)
      await fetch('remail/' + game.index, authorize)
    } catch (e) {
        setFail(`Something went wrong ('unable to process request, please contact support')`)
    } finally {
      setLoading(false)
    }
  }

  if (!active) {
    return <LinearProgress />
  }

  return (<div className={styles.root}>
    <Dialog
      open={confirm}
      onClose={() => setConfirm(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Do you want to redeem the prize for <b>{name}</b>?</DialogTitle>
      <DialogActions>
        <Button onClick={() => setConfirm(false)} color="primary">
          No
          </Button>
        <Button onClick={redeem}>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
    {fail && !loading && (<div className={styles.warningContainer}>
      <SnackbarContent className={styles.fail}
        message={(
          <Typography component="p" className={styles.warningText}>
            <Icon className={styles.warningIcon}>warning</Icon> {fail}
          </Typography>
        )}
      />
    </div>)}
    <form className={styles.form}
      noValidate
      onSubmit={(e) => { e.preventDefault() }}
      autoComplete="off">
      <TextField
        required
        margin="dense"
        id="name"
        name="name"
        label="name"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setName(e.target.value)}
        inputProps={fontColor}
        value={name}
        disabled={true}
      />
      <TextField
        required
        margin="dense"
        id="email"
        name="email"
        label="email"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setEmail(e.target.value)}
        inputProps={fontColor}
        value={email}
        disabled={true}
      />
      <TextField
        required
        margin="dense"
        id="mobile"
        name="mobile"
        label="mobile"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setMobile(e.target.value)}
        inputProps={fontColor}
        value={mobile}
        disabled={true}
      />
      {prizeCode !== "" && <TextField
        required
        margin="dense"
        id="prizeCode"
        name="prizeCode"
        label="prize code"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setPrizeCode(e.target.value)}
        inputProps={fontColor}
        value={prizeCode}
        disabled={true}
      />}
      {prize !== "" && <TextField
        required
        margin="dense"
        id="prize"
        name="prize"
        label="prize"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setPrizeCode(e.target.value)}
        inputProps={fontColor}
        value={prize}
        disabled={true}
      />}
      {bonus !== "" && <TextField
        required
        margin="dense"
        id="bonus"
        name="bonus"
        label="bonus"
        type="text"
        fullWidth
        variant="outlined"
        onChange={(e) => setPrizeCode(e.target.value)}
        inputProps={fontColor}
        value={bonus}
        disabled={true}
      />}
      {(game && !game.data.Redeemed && game.data.PrizeCode !== "") && <TextField
        required
        margin="dense"
        id="staff"
        name="staff"
        label="staff name"
        type="text"
        fullWidth
        variant="outlined"
        onFocus={() => onFocus('staffName')}
        onBlur={(e) => onBlur(e, 'staffName')}
        onChange={(e) => setStaffName(e.target.value)}
        inputProps={fontColor}
        value={staffName}
        disabled={loading}
        error={(submitted) && fields.staffName.error()}
      />}
      {(game && game.data && game.data.PrizeCode === "") && <Typography component="p" className={styles.warningText}>
            <Icon className={styles.warningIcon}>warning</Icon> Game lost
      </Typography>}
      {(game && game.data && game.data.Redeemed && game.data.PrizeCode !== "") && <Typography component="p" className={styles.warningText}>
            <Icon className={styles.warningIcon}>warning</Icon> Prize redeemed
      </Typography>}
      <div className={styles.formButtonWrapper}>
        {(game && !game.data.Redeemed && game.data.PrizeCode !== "") && <Button className={styles.formSubmit}
          variant="contained"
          type="submit"
          color="primary"
          disabled={loading}
          onClick={() => setConfirm(true)} >
          Redeeem
        </Button>}
        {loading && <CircularProgress size={24} className={styles.formProgress} />}
      </div>
      <div className={styles.formButtonWrapper}>
        {(game && !game.data.Redeemed && game.data.PrizeCode !== "") && <Button className={styles.formSubmit}
          variant="contained"
          type="submit"
          color="success"
          disabled={loading}
          onClick={() => resendEmail()} >
          Resend email
        </Button>}
        {loading && <CircularProgress size={24} className={styles.formProgress} />}
      </div>
    </form>
  </div>)
}

export default Game