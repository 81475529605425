import React, { useState } from 'react'
import moment from 'moment'
import { fetch } from '../api'
import { makeStyles, useTheme } from '@mui/styles'
import { DatePicker } from '@mui/x-date-pickers'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import LinearProgress from '@mui/material/LinearProgress'
import AppBar from '@mui/material/AppBar'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Paper from '@mui/material/Paper'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { exportToCsv } from './Constants'
import SnackbarContent from '@mui/material/SnackbarContent'
import IconButton from '@mui/material/IconButton'
import Icon from '@mui/material/Icon'

const rootStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1',
    background: 'transparent',
    overflowY: 'auto',
  },
  tabRoot: {
    display: 'flex',
    flex: '1 1'
  },
  list: {
    padding: 0,
    background: (props) => props.lights ? '#fffffff0' : '#1f1f1fd6'
  },
  listHeader: {
    background: theme.palette.primary.main
  },
  listHeaderText: {
    overflowWrap: 'break-word'
  },
  searchHeader: {
    background: (props) => props.lights ? '#ececec' : '#3e3e3e',
    justifyContent: 'space-between'
  },
  listItem: {
    display: 'grid',
    padding: 0,
  },
  empty: {
    padding: '1em',
    fontSize: '0.8em'
  },
  formSubmit: {
    marginLeft: 10
  },
}))

function generateCollectionRows(games) {
  const header = Object.keys(games[0])
  let rows = [header]
  for (const game of games) {
    rows.push(Object.values(game))
  }

  return rows
}

const Boxes = ({ authorize }) => {
  const lights = window.localStorage.getItem('lights') === 'on'
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const styles = rootStyles({ lights })
  const [from, setFrom] = useState(moment().subtract(1, 'months'))
  const [to, setTo] = useState(moment())
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  // tabs
  const [tab, setTab] = useState(0)

  function changeTab(index) {
    setTab(index)
  }

  const downloadGamesCSV = async () => {
    setLoading(true)
    setError(false)
    try {
      const resp = await fetch('history/' + from.startOf('day').unix() * 1000000000 + '/' + to.endOf('day').unix() * 1000000000)
      console.log(resp)
      exportToCsv("gamesPlayed.csv", generateCollectionRows(resp.sort((a, b) => a.created - b.created)))
      setLoading(false)
    } catch (e) {
      console.warn(e)
      setLoading(false)
      setError(true)
    }
  }

  const downloadAveragesCSV = async () => {
    setLoading(true)
    setError(false)
    try {
      const resp = await fetch('average')
      console.log(resp)
      exportToCsv("averages.csv", generateCollectionRows(resp))
      setLoading(false)
    } catch (e) {
      console.warn(e)
      setLoading(false)
      setError(true)
    }
  }

  return <Paper className={styles.root} elevation={0}>
    {loading && <LinearProgress />}
    {error && <SnackbarContent
      style={{
        backgroundColor: '#f1932c',
        maxWidth: 'unset'
      }}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={() => setError(false)}>
          <Icon>cancel</Icon>
        </IconButton>
      ]}
      message={(
        <Typography component="p" style={{ color: 'white' }}>
          <Icon style={{ verticalAlign: "bottom", color: "#f0cf81" }}>warning</Icon> Failed to download csv, please try again.
        </Typography>
      )}
    />}
    <AppBar key="boxesTabsHeader" position="sticky" color="default">
      <Tabs
        value={tab}
        onChange={(_e, index) => {
          if (index === 0) {
            changeTab(index)
          }
        }}
        indicatorColor="primary"
        textColor="primary"
        variant={mobile ? 'fullWidth' : 'standard'}
      >
        <Tab disableTouchRipple label="Games" />
      </Tabs>
      <List className={styles.list}
        component="nav">
        <ListItem className={styles.listHeader}>
          {(() => tab === 0 ?
            <ListItemText className={styles.listHeaderText} primary={'Games played: please open CSV file with MS Excel or Google Sheet'} /> :
            <ListItemText className={styles.listHeaderText} primary={'Game details'} />)()}
        </ListItem>
      </List>
      <List className={styles.list} component="nav">
        <ListItem>
          <DatePicker
            label="from"
            inputFormat="MM/DD/yyyy"
            disableMaskedInput
            value={from}
            disabled={loading}
            onChange={(newFrom) => setFrom(newFrom)}
            renderInput={(params) => <TextField variant="outlined" {...params} />}
          />
          <DatePicker
            label="to"
            inputFormat="MM/DD/yyyy"
            disableMaskedInput
            value={to}
            disabled={loading}
            onChange={(newTo) => setTo(newTo)}
            renderInput={(params) => <TextField className={styles.formSubmit} variant="outlined" {...params} />}
          />
          <Button className={styles.formSubmit}
            variant="contained"
            type="submit"
            color="success"
            disabled={loading}
            onClick={() => downloadGamesCSV()} >
            Download Games CSV
          </Button>
        </ListItem>
        <ListItem>
          <Button className={styles.formSubmit}
            variant="contained"
            type="submit"
            disabled={loading}
            onClick={() => downloadAveragesCSV()} >
            Download Averages CSV
          </Button>
        </ListItem>
      </List>
    </AppBar>
  </Paper>
}

export default Boxes