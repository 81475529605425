import React, { memo } from 'react'
import { Route, Routes } from 'react-router-dom'
import Settings from './Settings'
import R404 from '../404'
import Games from '../games/Games'
import Game from '../games/Game'


export default memo(({ dispatch, authorize }) => {
  return <Routes>
    <Route path="/" element={<Games authorize={authorize} />} />
    <Route exact path="/game/:id" element={<Game authorize={authorize} />} />
    <Route exact path="/settings" element={<Settings dispatch={dispatch} />} />
    <Route element={<R404/>} />
  </Routes>
})