import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { makeStyles, useTheme } from '@mui/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

const navStyles = makeStyles(theme => ({
  root: {
    zIndex: props => props.mobile ? 1301 : '',
    boxShadow: 'none'
  },
  titleContainer: {
    width: '176px',
    padding: '18px 0'
  },
  title: {
    fontStyle: 'italic',
    fontWeight: '100',
    fontVariant: 'small-caps',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  link: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  signUp: {
    marginRight: props => props.location.pathname !== '/login' ? 6 : 0,
    textTransform: 'unset'
  },
  blog: {
    marginRight: 6,
    background: '#ff7800',
    textTransform: 'unset'
  },
  logIn: {
    background: theme.palette.grey[400],
    textTransform: 'unset'
  },
  subtitle: {
    fontWeight: '500',
    fontSize: '0.88em'
  }
}))

const Nav = () => {
  const location = useLocation()
  const mobile = useTheme().breakpoints.down('xs')
  const styles = navStyles({
    mobile,
    location
  })

  return (<AppBar className={styles.root}
    color="default">
    <Toolbar>
      <div className={styles.titleContainer}>
        <Typography className={styles.title} variant="h5" component="h3">
          <Link className={styles.link} to={'/'}>City Planning</Link>
        </Typography>
        <Typography className={styles.subtitle} component="p">Dashboard</Typography>
      </div>
    </Toolbar>
  </AppBar>)
}

export default Nav